import store from "../store";

var i18n = require("../i18n").default;
let t = (key) => i18n.t(key);
export default [
  {
    path: "/viewFile/:path",
    name: "viewFile",
    meta: {
      requireAuth: false,
      hideDrawer: true,
      hideNavigation: true,
    },
  },
  {
    path: "/pageNotFound",
    name: "pageNotFound",
    component: () => import("../views/404.vue"),
    meta: {
      hideNavigation: true,
    },
  },
  // {
  //   path: "/home",
  //   name: "home",
  //   component: () => import("../views/home.vue"),
  //   meta: {
  //     hideDrawer: true,
  //   },
  // },
  {
    path: "/reports",
    name: t("altqaryr"),
    component: () => import("../views/reports/reports.vue"),
    meta: {
      single: t("tqryr"),
      endPoint: "Order",
      breadcrumbs: [{ text: t("altqaryr"), disabled: true }],
      requireAuth: true,
      auth: [
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test.vue"),
    meta: {
      hideDrawer: true,
    },
  },
  {
    path: "/login",
    name: t("login"),
    component: require("../views/auth/login.vue").default,
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/register",
    name: t("tsjyl-hsab-jdyd-k-dhyf"),
    component: require("../views/auth/register.vue").default,
    meta: {
      hideNavigation: true,
      requireAuth: false,
    },
  },
  {
    path: "*",
    meta: {
      requireAuth: true,
    },
    redirect: "/login",
  },

  {
    path: "/dashboard",
    name: "الرئيسية",
    component: () => import("../views/dashboard/dashboard.vue"),
    meta: {
      single: "الرئيسية",
      endPoint: "Dashboards",
      breadcrumbs: [{ text: "الرئيسية", disabled: true }],
      requireAuth: true,
      auth: [],
    },
  },
  {
    path: "/orderDataChild",
    name: t("almaamlat"),
    component: () => import("../views/orders/orderDataChild/index.vue"),
    meta: {
      single: t("almaamlat"),
      endPoint: "Dashboards",
      breadcrumbs: [{ text: t("almaamlat"), disabled: true }],
      requireAuth: true,
      hideDrawer: true,
      auth: ["User"],
    },
  },
  {
    path: "/profile",
    name: t("profile"),
    component: () => import("../views/profile/profile.vue"),
    meta: {
      single: t("profile"),
      endPoint: "Order",
      breadcrumbs: [{ text: t("profile"), disabled: true }],
      requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Guest",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/ordersReport",
    name: t("tqryr-almaamlat"),
    component: () => import("../views/orders/orders.vue"),
    meta: {
      single: t("tqryr-almaamlat"),
      endPoint: "Order",
      breadcrumbs: [{ text: t("tqryr-almaamlat"), disabled: true }],
      requireAuth: true,
      auth: ["Employee", "Outcoming", "Incoming", "Manager"],
    },
  },
  {
    path: "/ordersReport/:orderId/:orderName",
    name: t("khtwat-almaamlh"),
    component: () => import("../views/orders/orderNode/orderNode.vue"),
    meta: {
      single: t("khtwh"),
      endPoint: "OrderNode",
      breadcrumbs: [
        { text: t("almaamlat"), href: "/orders" },
        { text: t("khtwat-almaamlh"), disabled: true },
      ],
      requireAuth: true,
      auth: ["Employee", "Benefit", "Security", "Guest", "Manager"],
    },
  },
  {
    path: "/orders",
    name: t("almaamlat"),
    component: () => import("../views/orders/orders.vue"),
    meta: {
      single: t("maamlh"),
      endPoint: "Order",
      breadcrumbs: [{ text: t("almaamlat"), disabled: true }],
      requireAuth: true,
      auth: ["Employee", "Outcoming", "Incoming", "Manager"],
    },
  },
  {
    path: "/guestSubmission",
    name: t("maamlat-aldhyf"),
    component: () => import("../views/orders/guestSubmission.vue"),
    meta: {
      single: t("maamlh"),
      endPoint: "Order",
      breadcrumbs: [{ text: t("almaamlat"), disabled: true }],
      requireAuth: true,
      auth: ["Employee", "Outcoming", "Incoming", "Manager"],
    },
  },
  {
    path: "/guest",
    name: t("almaamlat"),
    component: () => import("../views/orders/guest.vue"),
    meta: {
      single: t("maamlh"),
      endPoint: "Order",
      breadcrumbs: [{ text: t("almaamlat"), disabled: true }],
      requireAuth: true,
      hideDrawer: true,
      auth: ["Guest"],
    },
  },
  {
    path: "/blackList",
    name: t("qaemh-almhzwryn"),
    component: () => import("../views/blackList/blackList.vue"),
    meta: {
      single: t("mhzwr"),
      endPoint: "Permit",
      breadcrumbs: [{ text: t("qaemh-almhzwryn"), disabled: true }],
      requireAuth: true,
      auth: ["Security"],
    },
  },
  {
    path: "/permitReports",
    name: t("tqryr-altsaryh"),
    component: () => import("../views/permits/permitReports.vue"),
    meta: {
      single: t("tqryr-altsaryh"),
      endPoint: "Permit",
      breadcrumbs: [{ text: t("tqryr-altsaryh"), disabled: true }],
      requireAuth: true,
      auth: ["Security"],
    },
  },
  {
    path: "/permitReportsPrint/:filters",
    name: t("tqryr-altsaryh"),
    component: () => import("../views/permits/permitReportsPrint.vue"),
    meta: {
      single: t("tqryr-altsaryh"),
      endPoint: "Permit",
      breadcrumbs: [{ text: t("tqryr-altsaryh"), disabled: true }],
      requireAuth: true,
      hideNavigation: true,
      auth: ["Security"],
    },
  },
  {
    path: "/permit",
    name: t("altsaryh-alamnyh"),
    component: () => import("../views/permits/permits.vue"),
    meta: {
      single: t("tsryh-amny"),
      endPoint: "Permit",
      breadcrumbs: [{ text: t("altsaryh-alamnyh"), disabled: true }],
      requireAuth: true,
      auth: ["Benefit", "Security"],
    },
  },
  {
    path: "/permitStep/:permitId/:permitName",
    name: t("khtwat-altsryh"),
    component: () => import("../views/permits/permitStep/permitStep.vue"),
    meta: {
      single: t("tsryh-amny"),
      endPoint: "Permit",
      breadcrumbs: [
        { text: t("altsaryh-alamnyh"), href: "/permit" },
        { text: t("khtwat-altsryh"), disabled: true },
      ],
      requireAuth: true,
      auth: ["Employee", "Benefit", "Security", "Manager"],
    },
  },
  {
    path: "/permitData/:permitStepId/:permitId",
    name: t("jdwl-altsryh"),
    component: () =>
      import("../views/permits/permitStep/permitData/permitData.vue"),
    meta: {
      single: t("jdwl-altsryh"),
      endPoint: "Permit",
      breadcrumbs: [
        { text: t("altsaryh-alamnyh"), href: "/permit" },
        { text: t("khtwat-altsryh"), href: "/permitStep" },
        { text: t("jdwl-altsryh"), disabled: true },
      ],
      requireAuth: true,
      auth: ["Employee", "Benefit", "Security", "Manager"],
    },
  },
  {
    path: "/gate",
    name: t("altsaryh-alamnyh"),
    component: () => import("../views/permits/gate.vue"),
    meta: {
      single: t("jdwl-altsryh"),
      endPoint: "Permit",
      breadcrumbs: [{ text: t("altsaryh-alamnyh"), disabled: true }],
      requireAuth: true,
      auth: ["Gate"],
      hideDrawer: true,
    },
  },
  {
    path: "/printPermit",
    name: t("tbaah-altkhawyl"),
    component: () => import("../views/permits/printPermit.vue"),
    meta: {
      single: t("tbaah-altkhawyl"),
      endPoint: "Permit",
      breadcrumbs: [{ text: t("altsaryh-alamnyh"), disabled: true }],
      requireAuth: true,
      auth: ["Security", "Benefit"],
      hideNavigation: true,
    },
  },

  {
    path: "/orderNode/:orderId/:orderName",
    name: t("khtwat-almaamlh"),
    component: () => import("../views/orders/orderNode/orderNode.vue"),
    meta: {
      single: t("khtwh"),
      endPoint: "OrderNode",
      breadcrumbs: [
        { text: t("almaamlat"), href: "/orders" },
        { text: t("khtwat-almaamlh"), disabled: true },
      ],
      requireAuth: true,
      auth: [
        "Employee",
        "Benefit",
        "Security",
        "Guest",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },

  {
    path: "/guest/orderNode/:orderId/:orderName",
    name: t("khtwat-almaamlh"),
    component: () => import("../views/orders/orderNode/orderNode.vue"),
    meta: {
      single: t("khtwh"),
      endPoint: "OrderNode",
      breadcrumbs: [
        { text: t("almaamlat"), href: "/orders" },
        { text: t("khtwat-almaamlh"), disabled: true },
      ],
      hideDrawer: true,
      requireAuth: true,
      auth: [
        "Employee",
        "Benefit",
        "Security",
        "Guest",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/orderData/:nodeId/:nodeName/:mainNodeId/:orderId/:orderNodeId/:workflowId/:actionType",
    name: t("almdkhlat-walajraaat"),
    component: () =>
      import("../views/orders/orderNode/orderData/orderData.vue"),
    meta: {
      single: t("almdkhlat-walajraaat"),
      endPoint: "OrderNode",
      breadcrumbs: [
        { text: t("almaamlat"), href: "/orders" },
        { text: t("khtwat-almaamlh"), disabled: true },
        { text: t("mtabah-almaamlh"), disabled: true },
      ],

      requireAuth: true,
      auth: [
        "Employee",
        "Benefit",
        "Security",
        "Guest",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/guest/orderData/:nodeId/:nodeName/:mainNodeId/:orderId/:orderNodeId/:workflowId/:actionType",
    name: t("almdkhlat-walajraaat"),
    component: () =>
      import("../views/orders/orderNode/orderData/orderData.vue"),
    meta: {
      single: t("almdkhlat-walajraaat"),
      endPoint: "OrderNode",
      breadcrumbs: [
        { text: t("almaamlat"), href: "/orders" },
        { text: t("khtwat-almaamlh"), disabled: true },
        { text: t("mtabah-almaamlh"), disabled: true },
      ],
      hideDrawer: true,
      requireAuth: true,
      auth: [
        "Employee",
        "Benefit",
        "Security",
        "Guest",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/visitor/orderData/:nodeId/:nodeName/:mainNodeId/:orderId/:orderNodeId/:workflowId/:actionType",
    name: "visitor",
    component: () =>
      import("../views/orders/orderNode/orderData/orderData.vue"),
    meta: {
      single: t("almdkhlat-walajraaat"),
      endPoint: "OrderNode",
      breadcrumbs: [
        { text: t("almaamlat"), href: "/orders" },
        { text: t("khtwat-almaamlh"), disabled: true },
        { text: t("mtabah-almaamlh"), disabled: true },
      ],
      hideDrawer: true,
      hideNavigation: true,
      requireAuth: false,
    },
  },
  {
    path: "/documentsList/:type",
    name: t("alsadr-walward"),
    component: () => import("../views/documents/documents.vue"),
    meta: {
      single: t("anshaa-ktab"),
      endPoint: "document",
      breadcrumbs: [{ text: t("alsadr-walward"), disabled: true }],
      requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/documentsList/settings",
    name: "اعدادات الطباعة",
    component: () => import("../views/documents/printSettings.vue"),
    meta: {
      single: "اعدادات الطباعة",
      endPoint: "document",
      breadcrumbs: [{ text: "اعدادات الطباعة", disabled: true }],
      requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/documents/create/:type",
    name: t("alsadr-walward"),
    component: () => import("../views/documents/add.vue"),
    meta: {
      single: t("anshaa-ktab"),
      endPoint: "document",
      breadcrumbs: [{ text: t("alsadr-walward"), disabled: true }],
      requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/document/:id",
    name: t("tfasyl-alktab"),
    component: () => import("../views/documents/detials.vue"),
    meta: {
      single: t("anshaa-ktab"),
      endPoint: t("tfasyl-alktab"),
      breadcrumbs: [{ text: t("alsadr-walward"), disabled: true }],
      requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/documents/ministries",
    name: t("aljhat"),
    component: () => import("../views/ministries/ministries.vue"),
    meta: {
      single: t("jhh"),
      endPoint: "Ministry",
      breadcrumbs: [{ text: t("aljhat"), disabled: true }],
      // requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/documents/categories",
    name: t("categories"),
    component: () => import("../views/categories/categories.vue"),
    meta: {
      single: t("jhh"),
      endPoint: "DocumentCategory",
      breadcrumbs: [{ text: t("categories"), disabled: true }],
      // requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/documents/edit/:id",
    name: t("tadyl-alktab"),
    component: () => import("../views/documents/add.vue"),
    meta: {
      single: t("tadyl-alktab"),
      endPoint: "Ministry",
      breadcrumbs: [{ text: t("tadyl-alktab"), disabled: true }],
      // requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/documentsShare/:id",
    name: t("msharkh-ktab"),
    component: () => import("../views/documents/share.vue"),
    meta: {
      single: t("msharkh-ktab"),
      endPoint: "Ministry",
      breadcrumbs: [{ text: t("msharkh-ktab"), disabled: true }],
      // requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/documentsComment/:id",
    name: t("hwamsh-ktab"),
    component: () => import("../views/documents/comments.vue"),
    meta: {
      single: t("hwamsh-ktab"),
      endPoint: "Ministry",
      breadcrumbs: [{ text: t("hwamsh-ktab"), disabled: true }],
      // requireAuth: true,
      auth: [
        "Admin",
        "Employee",
        "Benefit",
        "Security",
        "Outcoming",
        "Incoming",
        "Manager",
      ],
    },
  },
  {
    path: "/testchunck",
    name: "testchunck",
    component: () => import("../views/testchunck.vue"),
    meta: {
      single: "testchunck",
      endPoint: "testchunck",
      breadcrumbs: [{ text: "testchunck", disabled: true }],
      // requireAuth:true,
      auth: ["Admin", "Employee", "Benefit", "Security", "Manager"],
      hideDrawer: true,
    },
  },
  {
    path: "/treatment",
    name: "treatment",
    component: () => import("../views/treatment/treatment.vue"),
    meta: {
      requireAuth: false,
      hideDrawer: true,
      hideNavigation: true,
    },
  },
  {
    path: "/treatment/:id",
    name: "orderDataWithOutLogin",
    component: () => import("../views/treatment/orderDataWithOutLogin.vue"),
    meta: {
      requireAuth: false,
      hideDrawer: true,
      hideNavigation: true,
    },
  },
  // {
  //   path: "/",
  //   name: "SorE",
  //   component: () => import("../views/treatment/SorE.vue"),
  //   meta: {
  //     requireAuth: false,
  //     hideDrawer: true,
  //     hideNavigation: true,
  //   },
  // },
  {
    path: "/checked",
    name: "SorE",
    component: () => import("../views/treatment/checked.vue"),
    meta: {
      requireAuth: false,
      hideDrawer: true,
      hideNavigation: true,
    },
  },
  {
    path: "/home",
    name: "studentOrEmployee",
    component: () => import("../views/visitor/studentOrEmployee.vue"),
    meta: {
      requireAuth: false,
      hideDrawer: true,
      hideNavigation: true,
    },
  },
  {
    path: "/visitor",
    name: "visitor",
    component: () => import("../views/visitor/visitor.vue"),
    meta: {
      requireAuth: false,
      hideDrawer: true,
      hideNavigation: true,
    },
  },
  {
    path: "/visitor/createOrder/:workflowId",
    name: "createOrder",
    component: () => import("../views/visitor/createOrder.vue"),
    meta: {
      requireAuth: false,
      hideDrawer: true,
      hideNavigation: true,
    },
  },
];
